import cn from 'classnames';
import {
  CurrentPricePeriod,
  TariffsProductType,
} from 'src/components/TariffPlans/types';
import { PlansStatus } from 'src/components/TariffPlans/types';
import { ToolTip } from 'src/components/ToolTip';
import { Button } from 'src/uikit/Button';
import { CheckIcon } from 'src/uikit/icons';
import { Tag } from 'src/uikit/Tag';

import { camelToKebab } from '../../utils/camelToKebab';
import { PriceInCard } from '../PriceInCard';
import s from './PlanCard.module.scss';

type CharacteristicTypes = {
  title: string;
  value: string;
};

type PlanCardProps = {
  isActive: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  price: number;
  discountPrice?: number;
  currentPricePeriod: CurrentPricePeriod;
  planStatus?: PlansStatus;
  description?: string;
  characteristics: CharacteristicTypes[];
  onCheckboxClick?: () => void;
  checkBoxText?: string;
  checkBoxActive?: boolean;
  tooltipText?: string;
  tooltipHint?: string;
  connectButtonTitle?: string;
  onClickOnConnect?: () => void;
  productType: TariffsProductType;
  discountInPercent?: number;
  isFreeTier?: boolean;
  className?: string;
};

export function PlanCard({
  isActive,
  onMouseOver,
  onMouseLeave,
  price,
  currentPricePeriod,
  planStatus,
  description,
  characteristics,
  onCheckboxClick,
  checkBoxText,
  checkBoxActive,
  tooltipText,
  tooltipHint,
  connectButtonTitle = 'Подключить',
  onClickOnConnect,
  productType,
  discountInPercent,
  isFreeTier,
  className,
}: PlanCardProps) {
  const isShowFunctionalCheckbox =
    onCheckboxClick && checkBoxText && typeof checkBoxActive !== 'undefined';

  const isShowTooltip = tooltipText && tooltipHint;

  const isShowConnectButton = onClickOnConnect && productType;

  const isShowDiscount = typeof discountInPercent !== 'undefined';

  const discountValue =
    isShowDiscount && discountInPercent >= 100
      ? 'Бесплатно'
      : `-${discountInPercent}%`;

  return (
    <div
      className={cn(s.card, { [s.cardHovered]: isActive }, className)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className={s.top}>
        <div className={s.cardPrice}>
          <PriceInCard
            isFreeTier={isFreeTier}
            price={price}
            currentPricePeriod={currentPricePeriod}
            planStatus={planStatus}
            {...(isShowDiscount ? { discountInPercent } : {})}
          />
          {description && (
            <div className={s.cardDescription}>{description}</div>
          )}
        </div>
        <div className={s.cardInfo}>
          {characteristics.map(({ title, value }) => (
            <div key={title} className={s.cardRow}>
              <div className={s.cardInfoTitle}>{title}</div>
              <div className={s.cardInfoSubtitle}>{value}</div>
            </div>
          ))}
          {isShowFunctionalCheckbox && (
            <div className={s.cardCheck}>
              <div
                className={cn(s.cardCheckIconWrapper, {
                  [s.cardCheckIconWrapperChecked]: checkBoxActive,
                })}
                onClick={onCheckboxClick}
              >
                <CheckIcon
                  className={cn(s.cardCheckIcon, {
                    [s.cardCheckIconChecked]: checkBoxActive,
                  })}
                />
              </div>
              <span>{checkBoxText}</span>
            </div>
          )}
        </div>
      </div>
      {isShowTooltip && (
        <div className={cn(s.tag, { [s.tagHovered]: isActive })}>
          <span className={s.tagText}>{tooltipText}</span>
          <ToolTip color="#222222" hintClassName={s.hintClassName}>
            {tooltipHint}
          </ToolTip>
        </div>
      )}
      {isShowConnectButton && (
        <Button
          className={s.connect}
          variant={Button.variants.Outline}
          onClick={onClickOnConnect}
          analyticsOptions={{
            action: 'click',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: connectButtonTitle,
            uniqueId: `${camelToKebab(productType)}-card-connect`,
            transitionType: 'inside-link',
          }}
        >
          {connectButtonTitle}
        </Button>
      )}
      {isShowDiscount && (
        <div className={s.discountChip}>
          <Tag
            variant={Tag.variants.Primary}
            textColor={Tag.textColors.Graphite}
            className={s.discountChipTagBackground}
          >
            <span className={s.discountChipText}>{discountValue}</span>
          </Tag>
        </div>
      )}
    </div>
  );
}
